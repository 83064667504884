export default [
    {
        path: '/help',
        name: 'HelpPage',
        meta: { title: 'help.listTitle' },
        component: () => import('@/pages/help'),
    },
    {
        path: '/help/categories/create',
        name: 'pageHelpCategoriesCreate',
        meta: { title: 'helpCategories.createFormTitle', parent: {title: 'help.listTitle', to: '/help', tab: 'categories'} },
        component: () => import('@/pages/help/help-categories/create'),
    },
    {
        path: '/help/categories/:id/edit',
        name: 'pageHelpCategoriesEdit',
        meta: { title: 'helpCategories.editFormTitle', parent: {title: 'help.listTitle', to: '/help', tab: 'categories'} },
        component: () => import('@/pages/help/help-categories/id/edit'),
    },
    {
        path: '/help/articles/create',
        name: 'pageHelpArticlesCreate',
        meta: { title: 'helpArticles.createFormTitle', parent: {title: 'help.listTitle', to: '/help', tab: 'articles'} },
        component: () => import('@/pages/help/help-articles/create'),
    },
    {
        path: '/help/articles/:id/edit',
        name: 'pageHelpArticlesEdit',
        meta: { title: 'helpArticles.editFormTitle', parent: {title: 'help.listTitle', to: '/help', tab: 'articles'} },
        component: () => import('@/pages/help/help-articles/id/edit'),
    },
]
