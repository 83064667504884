export function stringCroppedView(string, length = 30) {
  if (!string) return
  return string.length > length
    ? string.substring(0, length) + '...'
    : string
}

export function toSnakeCase(str) {
  return str
    .replace(/\W+/g, ' ')
    .split(/ |\B(?=[A-Z])/)
    .map(word => word.toLowerCase())
    .join('_')
}