<template>
  <div class="upload-area">
    <div
        class="upload-area--content"
        :style="{ height }"
        :class="{ 'active': isActive && !disabled }"
        @dragenter.prevent.stop="onDragEnter"
        @dragover.prevent.stop="onDragOver"
        @dragleave.prevent.stop="onDragLeave"
        @drop.prevent.stop="onDrop"
    >
      <v-icon color="info" size="32">fi fi-rr-file-upload</v-icon>
      <div class="body-1 gray500--text mt-4">
        <span class="subtitle-2 cursor-pointer" @click="onUploadFileByClick">
          {{ $t('uploadArea.upload') }}
        </span>
        {{ $t('uploadArea.moveYourFileOr') }}
      </div>
      <div class="body-2 gray500--text mt-1">
        {{ $t(`uploadArea.requires${icon ? 'Icon' : ''}`) }}
      </div>
      <div
          v-if="$scopedSlots.actions"
          class="body-1 primary--text cursor-pointer mt-3">
        <slot name="actions"/>
      </div>
    </div>
    <slot
        name="list"
    >
      <v-list
          class="upload-area--list pa-0 mt-6">
        <v-list-item
            v-for="file in files"
            :key="file.id"
            class="upload-area--item px-0"
        >
          <v-list-item-avatar
              class="upload-area--item-avatar mr-3 my-0"
          >
            <ui-button
                is-icon
                theme="flat"
            >
              <v-icon color="info">
                mdi-file-outline
              </v-icon>
            </ui-button>
          </v-list-item-avatar>

          <v-list-item-content>
            <div class="d-flex justify-space-between align-center">
              <div class="upload-area--item-name text-truncate">
                {{ file.name }}
              </div>
              <div v-if="file.progress" class="upload-area--item-progress">
                {{ file.progress }} %
              </div>
            </div>
            <v-progress-linear v-show="file.progress" :value="file.progress" rounded/>
          </v-list-item-content>

          <v-list-item-action class="ml-3 my-0">
            <ui-button
                is-icon
                theme="flat"
                @click="onDelete(file)"
            >
              <v-icon color="info">
                mdi-delete-outline
              </v-icon>
            </ui-button>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </slot>
    <input
        ref="file"
        class="d-none"
        type="file"
        :multiple="limit > 1"
        :accept="icon ? acceptIcon : accept"
        @change="onChange($event.target.files)"
    >
  </div>
</template>

<script>
import {mapActions} from "vuex";
import {messageErrorRequest} from "@/helpers/validation";
import {generateUUID} from "@/helpers/functions";

export default {
  name: 'UploadArea',
  props: {
    height: {
      type: String,
      default: '155px',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Boolean,
      default: false,
    },
    maxSize: {
      type: Number,
      default: 60
    },
    limit: {
      type: Number,
      default: 1
    },
    customAction: {
      type: Function,
      default: null,
    },
    accept: {
      type: Array,
      default: () => ['png', 'jpg', 'jpeg', 'gif'],
    },
    acceptIcon: {
      type: Array,
      default: () => ['png', 'jpg', 'jpeg', 'svg'],
    },
    autoProceed: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isActive: false,
      files: [],
      preview: [],
    }
  },
  watch: {
    'files': {
      handler(files) {
        this.$emit('input', files)
      },
      deep: true
    }
  },
  methods: {
    ...mapActions('FileModule', ['loadFileWithConfig']),
    ...mapActions('NotificationModule', ['show']),
    onDragEnter() {
      this.isActive = true
    },
    onDragOver() {
      this.isActive = true
    },
    onDragLeave() {
      this.isActive = false
    },
    onDrop(e) {
      this.isActive = false
      if (!this.disabled) this.onChange(e.dataTransfer.files)
    },

    onUploadFileByClick() {
      if (!this.disabled) this.$refs.file.click()
    },
    onChange(files) {
      if (!files[0]) return
      const arrFiles = Array.from(files)
      if (this.checkErrors(arrFiles)) {
        return
      }
      this.loading = true
      if (this.autoProceed) {
        this.onLoadFiles(arrFiles)
      } else {
        const newFiles = arrFiles.map((file) => {
          const id = generateUUID()
          return {name: file.name, id, file}
        })
        this.files.push(...newFiles)
        newFiles.forEach(item => {
          this.preview.push({link: URL.createObjectURL(item.file), name: item.name, id: item.id})
        })
        this.$emit('updateFile', {preview: this.preview, files: this.files})
        this.preview = []
        this.files = []
      }
    },
    onLoadFiles(files) {
      this.files = files.map((file) => {
        const id = generateUUID()
        return {name: file.name, progress: 0, id, file}
      })
      const promises = this.files.map(({file, id}) => {
        const formData = new FormData()
        formData.append('file', file)
        return this.fetchData(formData, file, id)
      })
      this.onUploadFile(promises)
    },
    fetchData(formData, file, id) {
      const onUploadProgress = (e) => this.onProgress(e, file, id)

      if (this.customAction) {
        return this.customAction(formData, onUploadProgress)
      }

      return this.loadFileWithConfig({
        formData,
        config: {
          onUploadProgress
        }
      })
    },
    async onUploadFile(promises) {
      try {
        const responses = await Promise.all(promises)
        this.$emit('upload', responses)
      } catch (e) {
        this.files = []
        const message = messageErrorRequest(e)
        this.show({type: 'error', message})
      } finally {
        this.$refs.file.value = null
      }
    },
    onProgress(e, f, id) {
      const file = this.files.find(i => i.id === id)

      const percent = Math.floor(e.loaded * 100 / f.size)
      file.progress = percent > 100 ? 100 : percent
    },

    onDelete(file) {
      this.$refs.file.value = null
      this.files = this.files.filter(item => item.id !== file.id)
      this.$emit('update:delete-file', file)
    },

    checkErrors(files) {
      if (this.isLimitError(files)) {
        this.showErrorNotification(this.$t('uploadArea.limit', {limit: this.limit}))
        return true
      }
      return files.some(file => this.checkError(file))
    },
    checkError(file) {
      if ((file.size / (1024 * 1024)).toFixed(2) > this.maxSize) {
        this.showErrorNotification(this.$t('uploadArea.maxSize', {maxSize: this.maxSize}))
        return true
      } else if (!(this.icon ? this.acceptIcon : this.accept).includes(file.name.split('.').pop())) {
        this.showErrorNotification(this.$t('uploadArea.invalidType'))
        return true
      }
    },
    isLimitError(files) {
      const sum = files.length + this.files.length
      return sum > this.limit
    },
    showErrorNotification(message) {
      this.show({type: 'error', message})
    }
  },
}
</script>

<style lang="scss" scoped>
.upload-area {
  &--content {
    background: var(--v-gray50-base);
    border: 2px dashed var(--v-gray300-base);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: all .2s ease-out;

    &.active {
      border: 1px solid var(--v-black-darken3);
    }
  }

  &--item-avatar {
    background: var(--v-black-lighten7);
    border-radius: 8px;
  }

  &--item {
    min-height: 40px;

    &-name {
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: var(--v-black-darken3);
    }

    &-progress {
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      color: var(--v-black-lighten6);
    }
  }
}
</style>
