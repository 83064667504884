<template>
  <v-textarea
      v-model="getValue"
      :error-messages="getErrorMsg"
      :error="error"
      outlined
      dense
      hide-details="auto"
      v-bind="$attrs"
      v-on="$listeners"
      class="ui-textarea"
  >
    <template v-for="(_, name) in $slots" v-slot:[name]>
      <slot :name="name"/>
    </template>
  </v-textarea>
</template>

<script>
import {getErrorMsg} from '@/helpers/validation'

export default {
  name: "ui-textarea",
  props: {
    value: null,
    errorMessages: {
      type: [String, Array, Object],
    }
  },
  computed: {
    getValue: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
    error() {
      if (!this.value) return this.$attrs.error
      return this.value.$error
    },
    getErrorMsg() {
      if (Array.isArray(this.errorMessages) || typeof this.errorMessages === 'string') {
        return this.errorMessages
      }
      return getErrorMsg(this.errorMessages)
    }
  },
}
</script>

<style lang="scss">
.ui-textarea {
  .v-label--active {
    color: #5F6163 !important;
  }

  fieldset, .v-text-field .v-input__control {
    border-color: var(--v-black-lighten6);
  }

  .v-text-field__slot {
    .v-label {
      font-size: 12px !important;
      color: var(--v-black-lighten2);
    }

    textarea {
      font-size: 12px;
      color: var(--v-black-darken3) !important;
    }
  }
}

</style>
