<template>
  <ui-select
    ref="select"
    v-model="selectedOption"
    :options="filteredOptions"
    :limit="10"
    :internal-search="false"
    :loading="loading"
    v-bind="bindingsSelect"
    preserve-search
    lazy-loading
    :no-more-options="noMoreOptions"
    :last-load-page="searchFilters.page"
    v-on="otherListeners"
    @search-change="onSearchQueryChange"
    @load-more="onLoadMore"
    @input="onInput"
  >
    <template #label>
      <slot name="label">
        {{ labelName }}
      </slot>
    </template>

    <template v-if="$scopedSlots.option" #option="{ option }">
      <slot name="option" :option="option" />
    </template>

    <template v-if="$scopedSlots.singleLabel" #singleLabel="{ option }">
      <slot name="singleLabel" :option="option" />
    </template>

    <template v-if="$scopedSlots.iconLeft" #iconLeft>
      <slot name="iconLeft" />
    </template>

    <template v-if="$scopedSlots.iconRight" #iconRight>
      <slot name="iconRight" />
    </template>

    <template #noOptions>
      {{ $t('base.startTypingText') }}
    </template>

    <template v-if="$scopedSlots.noResult" #noResult>
      <slot name="noResult" />
    </template>
  </ui-select>
</template>
<script>
import { debounce, omit } from 'lodash'
const OPTIONS_LIST_CLASS = 'multiselect__content-wrapper'

export default {
  name: 'LazyLoadingSelect',
  props: {
    value: {
      type: [Object, String, Array, Number],
      default: null,
    },
    labelName: {
      type: String,
      default: '',
    },
    searchFunction: {
      type: Function,
      default: () => [],
    },
    optionsFilter: {
      type: Function,
      default: options => options,
    },
  },
  data() {
    return {
      selectedOption: null,
      options: [],
      loading: false,
      noMoreOptions: false,
      searchFilters: {
        page: 1,
        limit: 10,
        query: '',
        last: 1,
      },
    }
  },
  computed: {
    bindingsSelect() {
      return {
        ...omit(this.$attrs, ['value', 'searchFunction']),
      }
    },
    otherListeners() {
      return omit(this.$listeners, ['search-change', 'load-more', 'input'])
    },
    filteredOptions() {
      return this.optionsFilter(this.options)
    },
  },
  watch: {
    value: {
      handler(value) {
        this.selectedOption = value
      },
      immediate: true,
    },
  },
  mounted() {
    this.onSearchChange('')
  },
  methods: {
    onInput() {
      this.$emit('input', this.selectedOption)
    },
    onSearchQueryChange: debounce(function (query) {
      this.onSearchChange(query)
    }, 300),
    async onSearchChange(query) {
      this.loading = true

      try {
        this.searchFilters.page = 1
        this.searchFilters.query = query
        this.noMoreOptions = false
        const { limit, page } = this.searchFilters

        const { options, pagination } = await this.searchFunction({ query, limit, page })
        this.options = options

        const listEl = this.$refs.select.$el.getElementsByClassName(OPTIONS_LIST_CLASS)
        if (listEl.length) {
          listEl[0].scrollTo(0, 0)
        }

        this.searchFilters.last = pagination?.maxPageNumber || 1
        this.noMoreOptions = page >= this.searchFilters.last
      } catch (err) {
        console.error(err)
      } finally {
        this.loading = false
      }
    },
    async onLoadMore() {
      this.loading = true
      try {
        this.searchFilters.page += 1
        const { query, page, limit } = this.searchFilters

        const { options } = await this.searchFunction({ query, page, limit })
        this.options = [...this.options, ...options]
        this.noMoreOptions = page >= this.searchFilters.last
      } catch (err) {
        console.error(err)
      } finally {
        this.loading = false
      }
    },
    resetSearch() {
      this.searchFilters = {
        page: 1,
        limit: 10,
        query: '',
        last: 1,
      }
      this.onSearchChange('')
    },
  },
}
</script>
