<template>
  <div class="ui-data-table-outer">
    <v-data-table
        class="ui-data-table loader-fullscreen"
        :class="[getClass(), {'hidden-footer': hideFooter}]"
        v-bind="$attrs"
        v-on="$listeners"
        :headers="filteredHeaders"
        :items="items"
        hide-default-footer
        :no-data-text="$t('base.noData')"
        :header-props="headerProps"
        :options="options"
        :loading="loading"
        :show-select="showSelect"
        @item-selected="handleItemSelection"
    >
      <template v-slot:top v-if="!hideTop">
        <div v-if="title" class="pt-7 px-6 pb-2 font-weight-bold text-h2 black--text text--darken-6">
          {{ title }}
        </div>
        <v-container fluid class="pa-4">
          <v-row dense>
            <v-col v-if="$slots.prependInner" cols="12" class="d-flex justify-space-between">
              <slot name="prependInner" />
            </v-col>

            <v-col v-if="$slots.title" cols="12">
              <slot name="title" />
            </v-col>

            <v-col v-if="$slots.filters" cols="12">
              <slot name="filters" />
            </v-col>

            <v-col cols="12" class="d-flex justify-space-between align-center">
              <div class="search-wrapper d-flex">
                <v-text-field
                    class="search-table"
                    v-if="!hideSearch"
                    v-model="searchField"
                    background-color="gray50"
                    filled
                    dense
                    solo
                    flat
                    outlined
                    height="36"
                    prepend-inner-icon="mdi-magnify"
                    hide-details
                    :placeholder="$t('base.search')"
                />
                <ui-button
                    class="search-btn"
                    dense
                    flat
                    @click="onInputSearchQuery(searchField)"
                >
                  {{$t('base.search')}}
                </ui-button>
              </div>

              <slot name="afterSearch" />

              <div v-if="isShowActions" class="ml-3">
                <slot name="action" />
                <slot name="defaultCreate">
                  <ui-button
                      v-if="!hideDefaultCreate"
                      v-permission="btnCreate.permission"
                      @click="btnCreate.isModal ? $emit('createClick') : $router.push({ name: btnCreate.path })"
                  >
                    <template #iconLeft>
                      <v-icon color="white" size="16">{{ btnCreate.icon || 'fi fi-br-plus-small' }}</v-icon>
                    </template>
                    {{ btnCreate.title }}
                  </ui-button>
                </slot>
              </div>
            </v-col>
          </v-row>
        </v-container>

        <ui-table-actions
            v-if="showTableActions"
            :selected-count="displaySelectedCount"
            @close="unselectAll"
        >
          <slot name="actions" />
        </ui-table-actions>
      </template>

      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
        <slot :name="slot" v-bind="scope" />
      </template>

      <template #header.data-table-select="{ on }">
        <v-popover
            placement="right"
            container=".ui-data-table"
            trigger="manual"
            :open="hasSomeSelected"
            :auto-hide="false"
        >
          <v-simple-checkbox
              :value="isAllSelected"
              :indeterminate="isSomeSelected"
              color="primary"
              v-on="on"
              :ripple="false"
              class="ui-table-checkbox"
              :class="{ 'ui-table-checkbox--indeterminate': isSomeSelected }"
              @input="onSelectionToggle"
          />

          <template #popover>
            <ui-card class="px-4 py-2">
              <div class="d-flex">
                <div class="text-body-1">
                  {{ $t('base.selected') }}
                  <span class="primary--text">{{ displaySelectedCount }}</span>
                  {{ $t('base.items') }}.
                </div>

                <div
                    class="primary--text text-body-1 font-weight-bold mx-2 cursor-pointer"
                    @click="selectAll"
                >
                  {{ $t('base.selectAll') }} ({{ totalItems }})
                </div>

                <div
                    class="primary--text text-body-1 font-weight-bold cursor-pointer"
                    @click="unselectAll"
                >
                  {{ $t('base.clearAll') }}
                </div>
              </div>
            </ui-card>
          </template>
        </v-popover>
      </template>

      <template v-slot:item.data-table-select="{ item, select }">
        <ui-checkbox :value="isSelected(item)" @input="select($event)" @click.stop />
      </template>
    </v-data-table>
    <div class="ui-footer" v-if="!hideFooter">
      <div class="d-flex align-self-start search-select-wrapper">
        <span class="limit-pages">{{ $t('base.rowsPerPage') }}</span>
        <v-select
            v-model="localOptions.pagination.limit"
            :items="itemsLimit"
            dense
            filled
            outlined
            hide-details="auto"
            append-icon="mdi-chevron-down"
            background-color="black lighten-7"
            @change="onChangePaginationLimit(localOptions)"
            class="px-2 search-select"
        />
        <div class="limit-pages">
          <span class="limit-pages pr-8">{{ $t('base.of') }} {{ localOptions.total }}</span>
        </div>
      </div>

      <ui-pagination
          v-model="localOptions.pagination"
          :total-items="localOptions.totalItems"
          @input="
              $emit('update:options', { ...localOptions, page: localOptions.pagination.page })
              $emit('reFetchData')
            "
      />
    </div>
  </div>
</template>

<script>
import UiTableActions from '@/components/ui/framework/general/UITableActions.vue'
import UiButton from "@/components/ui/framework/general/UIButton.vue";

export default {
  name: 'ui-date-table',
  components: {
    UiButton,
    UiTableActions,
  },
  props: {
    itemsLimit: {
      type: Array,
      default: () => {
        return [1, 10, 20, 50, 100]
      },
    },
    headers: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    columnsToShow: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hideTop: {
      type: Boolean,
      default: false,
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    hideSearch: {
      type: Boolean,
      default: false,
    },
    hideDefaultCreate: {
      type: Boolean,
      default: false,
    },
    btnCreate: {
      type: Object,
      default: () => {
        return {
          path: '',
          title: '',
          icon: false,
          isModal: false,
          permission: [],
        }
      },
    },
    isList: {
      type: Boolean,
      default: false,
    },
    selectedItems: {
      type: Array,
      default: () => [],
    },
    selectionExclusive: {
      type: Boolean,
      default: false,
    },
    // function for disable possibility to select item
    selectableCondition: {
      type: Function,
      default: null,
    },
    selectableKey: {
      type: String,
      default: 'id',
    },
    totalItems: {
      type: Number,
      default: 0,
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
    isShowActions: {
      type: Boolean,
      default: true,
    },
    noneBoxShadow: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    headerProps: {
      type: Object,
      default: () => ({
        // for default desc sort icon must be mdi-chevron-down
        sortIcon: 'mdi-unfold-more-horizontal',
      }),
    },
  },
  data() {
    return {
      searchField: '',
      filteredHeaders: [],
      localOptions: null,
      footerKey: 0
    }
  },
  computed: {
    itemsWithEnabledSelection() {
      return this.items.filter(item => !this.checkIfItemSelectionDisabled(item))
    },
    isPageItemsSelected() {
      return this.itemsWithEnabledSelection.every(item =>
        this.selectionExclusive ? !this.checkItemSelected(item) : this.checkItemSelected(item),
      )
    },
    isAllSelected() {
      return (
        (this.selectionExclusive && this.selectedItemsCount === 0) ||
        (this.selectedItemsCount === this.totalItems && this.totalItems > 0)
      )
    },
    selectedItemsCount() {
      return this.selectedItems.length
    },
    displaySelectedCount() {
      return !this.selectionExclusive
        ? this.selectedItemsCount
        : this.totalItems - this.selectedItemsCount
    },
    hasSomeSelected() {
      return this.selectedItemsCount !== 0 || this.selectionExclusive
    },
    isSomeSelected() {
      return this.hasSomeSelected && !this.isAllSelected
    },
    showTableActions() {
      return this.hasSomeSelected && this.showSelect && this.$scopedSlots.actions
    },
  },
  watch: {
    columnsToShow(data) {
      this.setHeaders(data)
    },
    options(newVal) {
      this.localOptions = newVal
    }
  },
  created() {
    this.setHeaders(this.columnsToShow)
    this.searchField = this.options.search
    this.localOptions = this.options
  },
  methods: {
    onInputSearchQuery(query) {
      this.$emit('update:search', query)
    },
    setHeaders(selectedHeaders = []) {
      if (!selectedHeaders.length) {
        this.filteredHeaders = [...this.headers]
        return
      }

      const colIDs = selectedHeaders.map(selectedItem => selectedItem.value)
      this.filteredHeaders = this.headers.filter(item => colIDs.includes(item.value))
    },
    getClass() {
      return [
        { 'ui-data-table-clickable': this.$listeners['click:row'] },
        { 'ui-loading': this.loading },
        { 'ui-box-shadow': this.noneBoxShadow },
      ]
    },
    onChangePaginationLimit(options) {
      this.$emit('update:options', {
        ...options,
        pagination: {
          ...options.pagination,
          page: 1,
        },
        page: 1,
        itemsPerPage: options.pagination.limit,
      })
      this.$emit('reFetchData')
    },
    onSelectionToggle() {
      if (this.isPageItemsSelected) {
        if (this.selectionExclusive) {
          return this.handleCurrentPageSelection(this.items)
        }
        this.handleCurrentPageDeselection(this.items)
      } else {
        if (this.selectionExclusive) {
          return this.handleCurrentPageDeselection(this.items)
        }
        this.handleCurrentPageSelection(this.items)
      }
    },
    checkIfItemSelectionDisabled(item) {
      return typeof this.selectableCondition === 'function' && !this.selectableCondition(item)
    },
    findItemIndexInSelected(item) {
      return this.selectedItems.findIndex(
        selectedItem => selectedItem[this.selectableKey] === item[this.selectableKey],
      )
    },

    checkItemSelected(item) {
      const index = this.findItemIndexInSelected(item)
      return index !== -1
    },
    handleCurrentPageSelection(items) {
      const selectedItems = this.selectedItems
      const itemsToAdd = items.filter(
        item => !this.checkItemSelected(item) && !this.checkIfItemSelectionDisabled(item),
      )

      this.updateSelected([...selectedItems, ...itemsToAdd])
    },
    handleCurrentPageDeselection(items) {
      this.updateSelected(
        this.selectedItems.filter(item => {
          return !items.find(
            unselectedItem => unselectedItem[this.selectableKey] === item[this.selectableKey],
          )
        }),
      )
    },
    updateSelected(updatedSelectedItems) {
      this.$emit('update:selected-items', updatedSelectedItems)
    },
    selectAll() {
      this.resetSelection()
      this.setSelectedAll(true)
    },
    unselectAll() {
      this.resetSelection()
      this.setSelectedAll(false)
    },
    resetSelection() {
      this.updateSelected([])
    },
    setSelectedAll(value = false) {
      this.$emit('update:selection-exclusive', value)
    },
    handleItemSelection({ item }) {
      const indexInSelected = this.findItemIndexInSelected(item)
      const updatedSelectedItems =
        indexInSelected === -1
          ? [item, ...this.selectedItems]
          : this.selectedItems.filter((item, itemIndex) => itemIndex !== indexInSelected)

      this.updateSelected(updatedSelectedItems)
    },
    isSelected(item) {
      return this.selectionExclusive ? !this.checkItemSelected(item) : this.checkItemSelected(item)
    },
  },
}
</script>

<style lang="scss">
.ui-data-table-outer {
  box-shadow: 0 1px 10px rgba(188, 188, 188, 0.12), 0 4px 5px rgba(188, 188, 188, 0.14),
  0 2px 4px -1px rgba(188, 188, 188, 0.2);
  border-radius: 8px;
}

.ui-footer {
  background-color: white;
  padding: 16px;
  border-radius: 0 0 8px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .search-select-wrapper {
    align-items: center;
    .limit-pages {
      font-size: 12px !important;
      font-weight: 400;
      line-height: 12px !important;
      letter-spacing: -0.12px;
      text-align: left;
      color: #6B7280;
    }
  }

  .search-select {
    width: 74px;
    max-height: 30px;
    border-radius: 8px;
    fieldset,
    .v-text-field .v-input__control {
      border-color: var(--v-black-lighten6);
    }
    .v-select__slot {
      .v-label {
        font-size: 14px !important;
        color: var(--v-black-darken6);
      }
      .v-select__selections {
        font-size: 12px;
        color: var(--v-black-darken6);

        .v-chip {
          margin-top: 5px;
        }
      }
      .v-icon {
        font-size: 20px;
        color: var(--v-black-lighten2);
      }
    }
    .v-input__control {
      .v-select__selections {
        height: 30px;

        .v-select__selection--comma {
          margin-right: 0;
          color: var(--v-black-darken6) !important;
        }
      }

      .v-input__slot {
        padding: 0 8px !important;
        min-height: 30px !important;

        .v-input__append-inner {
          margin-top: 6px;
          padding-left: 0;

          .v-input__icon {
            height: 18px;

            &--append {
              min-width: 18px;
              width: 18px;
            }
          }
        }

        .v-icon {
          font-size: 16px;
        }
      }
    }
  }
}

.ui-data-table {
  // pos: relative must have for correct the "select all" popover position
  position: relative;
  border-radius: 8px 8px 0 0;
  border-bottom: 1px solid #E5E7EB;
  &.hidden-footer {
    border-radius: 8px;
  }
  .ui-box-shadow {
    box-shadow: none;
  }
  .v-data-table__wrapper > table > thead {
    background-color: #F9FAFB;
    & > tr {
      span {
        text-transform: uppercase;
        font-weight: 600;
      }
      &:last-child > th {
        white-space: nowrap;
      }
    }
  }

  .v-data-table__expand-icon {
    color: var(--v-primary-base);
  }

  .v-data-table__wrapper {
    //overflow-x: inherit;
    overflow-y: inherit;

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--v-black-darken3);
      border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #f3f3f3;
    }

    scrollbar-color: var(--v-black-darken3) #f3f3f3;
    scrollbar-width: thin;
  }

  thead > tr > th > span {
    color: var(--v-gray500-base);
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
  }

  tbody > tr {
    td {
      color: var(--v-gray500-base);
      font-weight: 400;
      font-size: 14px !important;
      line-height: 150%;
      height: 40px !important;
      text-overflow: ellipsis;
      padding: 16px!important;
    }
  }

  thead > tr:last-child > th {
    border-bottom: 1px var(--v-black-lighten6) solid !important;
  }

  tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background: white !important;
    transform: scale(1, 1);
  }
  .v-data-table__wrapper
    tbody
    > tr:last-child:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    box-shadow: none !important;
    background: transparent !important;
  }

  tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row),
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > th:not(.v-data-table__mobile-row) {
    border-bottom: 1px var(--v-black-lighten6) solid !important;
  }

  .search-table {
    width: 385px;
    border-radius: 8px 0 0 8px;

    .mdi {
      font-size: 18px;
      color: var(--v-gray500-base);
    }

    .v-input__slot,
    .v-input__control {
      border-radius: 8px 0 0 8px;
      min-height: 36px !important;
    }

    .v-input__control .v-input__slot fieldset {
      border-color: var(--v-gray300-base)!important;
    }

    .v-text-field__slot {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0;
      text-align: left;

      input {
        color: var(--v-black-darken3);
      }

      input::placeholder {
        color: var(--v-gray500-base) !important;
      }
    }
  }
  .search-btn {
    color: var(--v-white-base);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .search-field {
    width: 220px;
  }
  .nested-table {
    .v-data-table-header {
      visibility: collapse;
    }
  }
}
.ui-data-table-clickable {
  tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    cursor: pointer;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
}

.ui-loading {
  th.sortable,
  th.sortable i {
    pointer-events: none !important;
  }
}

.ui-table-checkbox {
  .v-input--selection-controls__input {
    height: 16px;
    width: 16px;
  }

  .v-icon {
    font-size: 20px;
    color: var(--v-black-lighten3);
  }

  &--indeterminate {
    .v-icon {
      color: var(--v-black-darken2);
    }
  }
}
</style>
