/*
  Danger! Do not remove any comments from this file, generator will be broken!
 */

export const locale = {
  // __GENERATED_TRANSLATIONS__
  editor: {
    placeholder: 'Write text here ...'
  },
  help: {
    listTitle: 'Help'
  },
  helpArticles: {
    listTitle: 'Help articles',
    editFormTitle: 'Update help article',
    createFormTitle: 'Create help article',
    formFieldsBlockTitle: 'Main',
    formCreateUpdateSubmitErrorMessage: 'Request error',
    menuItemName: 'Help articles',
    createBtn: 'New article',
    table: {
      title: 'Title',
      category: 'Categories',
      createdAt: 'Created',
      active: 'Status',
      actions: '',
    },
    field: {
      name: 'Name*',
      title: 'Article title',
      cover: 'Article cover',
      enterTitle: 'Enter article title',
      metaTitle: 'Meta title',
      enterMetaTitle: 'Enter meta title',
      metaDesc: 'Meta description',
      enterMetaDesc: 'Enter meta description',
      content: 'Enter article content',
      placeholder: 'Sum*',
      active: 'Status',
      body: 'Body',
      selectActive: 'Select status',
      category: 'Category',
      selectCategory: 'Select category',
    },
  },
  helpCategories: {
    listTitle: 'Help categories',
    editFormTitle: 'Update existing category',
    createFormTitle: 'Create new category',
    createdSuccess: 'Created successfully',
    updatedSuccess: 'Updated successfully',
    formFieldsBlockTitle: 'Main',
    formCreateUpdateSubmitErrorMessage: 'Request error',
    menuItemName: 'Help categories',
    createBtn: 'New category',
    categoryIcon: 'Category icon',
    categoryTitle: 'Category title',
    body: 'Body',
    table: {
      title: 'Title',
      active: 'Status',
      actions: '',
    },
  },
  blog: {
    listTitle: 'Blog',
    articles: 'Articles',
    categories: 'Categories'
  },
  blogArticles: {
     listTitle: 'Blog articles',
     editFormTitle: 'Update blog article',
     createFormTitle: 'Create blog article',
     formFieldsBlockTitle: 'Main',
     formCreateUpdateSubmitErrorMessage: 'Request error',
     menuItemName: 'Blog articles',
     createBtn: 'New article',
     table: {
       title: 'Title',
       category: 'Categories',
       createdAt: 'Created',
       active: 'Status',
       actions: '',
     },
     field: {
       name: 'Name*',
       title: 'Article title',
       cover: 'Article cover',
       enterTitle: 'Enter article title',
       metaTitle: 'Meta title',
       content: 'Content',
       enterMetaTitle: 'Enter meta title',
       metaDesc: 'Meta description',
       enterMetaDesc: 'Enter meta description',
       placeholder: 'Sum*',
       active: 'Status',
       selectActive: 'Select status',
       category: 'Category',
       selectCategory: 'Select category',
     },
  },
   
  blogCategories: {
     listTitle: 'Blog categories',
     editModalTitle: 'Update existing category',
     createModalTitle: 'Create new category',
     createdSuccess: 'Created successfully',
     updatedSuccess: 'Updated successfully',
     formFieldsBlockTitle: 'Main',
     formCreateUpdateSubmitErrorMessage: 'Request error',
     menuItemName: 'Blog categories',
     createBtn: 'New category',
    table: {
      title: 'Title',
      active: 'Status',
      actions: '',
    },
     modal: {
       categoryTitle: 'Category title',
       enterTitle: 'Enter category title',
       status: 'Status',
       selectStatus: 'Select status',
     },
     field: {
       name: 'Name*',
       placeholder: 'Sum*',
       active: 'Status',
     },
  },
   
  waitingList: {
     listTitle: 'Waiting list',
     fileName: 'WaitingList',
     editFormTitle: 'Update waiting list',
     createFormTitle: 'Create waiting list',
     formFieldsBlockTitle: 'Main',
     formCreateUpdateSubmitErrorMessage: 'Request error',
     menuItemName: 'Waiting list',
     table: {
       fullName: 'Full name',
       email: 'Email',
       phone: 'Phone number',
       createdAt: 'Date and time',
       actions: 'Actions',
     },
     field: {
       name: 'Name*',
       placeholder: 'Sum*',
       active: 'Status',
     },
  },
  
  lang: {
    ru: 'Russian',
    en: 'English',
    fr: 'French'
  },
  authLogin: {
    username: 'Username',
    password: 'Password',
    loginIn: 'Login In',
    login: 'Login',
    loginBtn: 'Login now',
    invalidCredentials: 'Wrong email or password ',
  },
  base: {
    save: 'Save',
    cancel: 'Cancel',
    remove: 'Remove',
    delete: 'Delete',
    create: 'Create',
    createNew: 'Create new',
    confirm: 'Confirm',
    areYouSureToDelete: 'Are you sure want to delete?',
    areYouSureToDeactivate: 'Confirm deactivation',
    areYouSureToActivate: 'Confirm activation',
    yes: 'Yes',
    no: 'No',
    add: 'Add New',
    edit: 'Edit',
    preview: 'Preview',
    editing: 'Editing',
    time: 'Time',
    back: 'Back',
    filters: 'Filters',
    apply: 'Apply',
    selectAll: 'Select all',
    clearAll: 'Clear all',
    deactivate: 'Deactivate',
    activate: 'Activate',
    listStatusActive: 'Active',
    listStatusInactive: 'Inactive',
    search: 'Search',
    noData: 'No data',
    all: 'All',
    allCount: 'All ({count})',
    onlyChosen: 'Only chosen',
    attention: 'Attention',
    fill: 'Fill',
    validationError: 'Validation error',
    validationErrorServer: 'validation server error',
    noMoreItems: 'No more items',
    noElementsFound: 'No elements found',
    import: 'Import',
    exportTemplate: 'Export template',
    exportCSV: 'Export to CSV',
    resetFilters: 'Reset',
    createdAt: 'Created at',
    createdBy: 'Created by',
    actions: 'Actions',
    city: 'City',
    district: 'District',
    region: 'Region',
    country: 'Country',
    today: 'Today',
    yesterday: 'Yesterday',
    currentMonth: 'Current month',
    noDataAvailable: 'No data available',
    startTypingText: 'Start typing text',
    change: 'Change',
    month: 'Month',
    day: 'Day',
    hour: 'Hour',
    minute: 'Minute',
    toTheBegining: 'To the beginning',
    warning: 'Warning',
    choosePeriod: 'Choose period',
    selectPeriod: 'Select period',
    rangeDays: 'Range days',
    filtersActive: 'Active',
    filtersDeactivated: 'Deactivated',
    filtersAll: 'All',
    selectFields: 'Select fields',
    open: 'Hide',
    close: 'Show',
    close2: 'Close',
    reset: 'Reset',
    operator: 'Operator',
    notOperator: 'Not operator',
    export: 'Export',
    exportReport: 'Export report',
    manualRequest: 'Manual request',
    apiAutoRequests: 'API auto requests:',
    images: 'Images',
    product: 'Product',
    selected: 'Selected',
    items: 'items',
    onPage: 'Showing',
    rowsPerPage: 'Rows per page',
    onThePage: 'On the page',
    paginationOnPage: 'Showing',
    paginationFrom: 'from',
    history: 'History',
    field: 'Field',
    success: 'Success',
    comments: 'Comments',
    choose: 'Choose',
    value: 'Value',
    role: 'Role',
    group: 'Group',
    niche: 'Niche',
    total: 'Total',
    from: 'from',
    outOf: 'out of',
    of: 'of',
    mobileView: 'For correct display use desktop version',
    goToMain: 'Go to main',
    returnToMainPage: 'Return to main page',
    showNumber: 'Show number',
    failedToLoad: 'Failed to load',
    failedToLoadDescription: 'Error while loading page. Try reload page',
    limitText: 'and {count} more',
    addSelectedOptions: 'Add selected options',
    removeSelectedOptions: 'Remove selected options',
    ok: 'Хорошо',
    selectDate: 'Select date',
    presets: {
      today: 'Today',
      todayAndYesterday: 'Today and yesterday',
      yesterday: 'Yesterday',
      currentWeek: 'Current week',
      previousWeek: 'Previous week',
      currentMonth: 'Current month',
      previousMonth: 'Previous month',
      currentYear: 'Current year',
    },
    beWaiting: 'Wait',
    dataLoading: 'The data is being processed',
    asidePlatformManagement: 'Platform management',
    asideUsersManagement: 'Users management',
    asideReports: 'Reports',
  },
  error: {
    invalid: '{name} is invalid',
    required: '{name} required',
    requiredIf: '{name} required',
    email: 'Wrong Email format',
    maxLength: 'Max length - {length} symbols',
    minLength: 'Min length - {length} symbols',
    maxValue: 'Max value: {value}',
    minValue: 'Min value: {value}',
    onlyNumbers: 'Only numbers',
    onlyIntegerNumbers: 'Only integer numbers',
    onlyIntegerPositiveNumbers: 'Only integer positive numbers',
    onlyWords: 'Only words',
    allow: 'Your password should contain: ',
    sameField: 'Passwords should be the same',
    regexPassword: 'Wrong password format',
    url: 'Wrong URL',
    dateFromLess: 'Date "from" should be less than date "to"',
    dateToLess: 'Date "to" should be greater than date "from"',
    commaSpaceRule: 'Start and end of field shouldn\'t contain space or comma',
    lengthDemand: '8 symbols at least',
    capitalDemand: 'One capital letter',
    lowercaseDemand: 'One lowercase letter',
    numberDemand: 'One digit (0-9)',
    symbolDemand: 'One symbol (!@#%&*.)',
    latinDemand: 'Password contains only latin letters',
    invalidValue: 'Wrong value',
    notBeEmpty: 'Value shouldn\'t be empty',
    error403Title: '403!',
    error403Desc: 'Page doesn\'t exists or you don\'t have access.',
    error404Title: '404!',
    error404Desc: 'Something seems to have gone wrong! The page you are requesting does not exist. Maybe it is outdated or deleted.',
    emailExist: 'This email is already registered',
  },
  uploadArea: {
    maxSize: 'Max file size {maxSize} MB',
    moveYourFileOr: 'or drag and drop',
    upload: 'Click to upload',
    limit: 'Max files quantity - {limit}',
    requires: 'PNG, JPG, JPEG or GIF (Ratio 16:9)',
    requiresIcon: 'PNG, JPG, JPEG or SVG (Ratio 1:1)',
    invalidType: 'Invalid file type',
  },
}

export default locale
