import Vue from 'vue'

import moment from 'moment'
import VueMoment from 'vue-moment'

require('moment/locale/en-ca')

// moment.locale('ru')

Vue.use(VueMoment, { moment })
