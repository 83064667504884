<template>
  <v-card
      v-bind="$attrs"
      v-on="$listeners"
      class="ui-card"
      flat
  >
    <template v-for="(_, name) in $slots" v-slot:[name]>
      <slot :name="name"/>
    </template>
  </v-card>
</template>

<script>
export default {
  name: "ui-card"
}
</script>

<style lang="scss">
.ui-card {
  padding: 24px;

  .v-card__title {
    color: var(--v-gray900-base);
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    padding: 0 0 10px 0;
  }
}
</style>
