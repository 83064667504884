<template>
  <v-tabs
      class="ui-tabs"
      background-color="transparent"
      :grow="grow"
      v-bind="$attrs"
      :height="height"
      v-on="$listeners"
  >
    <v-tab
      v-for="item in tabs"
      :key="item.key"
      :disabled="!!item.disabled"
      exact
      class="gray900--text px-3"
      :class="{'v-tab-small': tabSmall, 'tab-item-active': value === item.key}"
      @click="$emit('input', item.key)"
    >
      <v-icon
        size="18"
        :color="value === item.key ? 'purple600' : 'gray300'"
        class="mr-2"
      >
        {{ value === item.key ? 'fi fi-ss-dot-circle' : 'fi fi-ts-circle' }}
      </v-icon>{{ item.title }}
    </v-tab>
  </v-tabs>
</template>

<script>
export default {
  name: 'ui-tabs',
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    tabSmall: {
      type: Boolean,
      default: false,
    },
    height: {
      type: [String, Number],
      default: 40,
    },
    grow: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number],
      default: 'en'
    },
    query: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getValue: {
      get() {
        return this.value
      },
    }
  },
  created() {
    if (this.query) {
      this.$watch('value', (val) => {
        this.$router.replace({ path: this.$route.path, query: { ...this.$route.query, tab: val }, }).catch(() => {})
      })
      const tab = this.$route.query.tab
      if (tab) this.$emit('input', tab)
    }
  }
}
</script>

<style lang="scss">
.ui-tabs {
  .v-tab {
    background-color: var(--v-white-base);
    border-radius: 8px 8px 0 0;
    margin-right: 4px;
    overflow: hidden;

    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    text-transform: none;
  }
  .tab-item-active {
    border: 1px solid var(--v-gray200-base);
    border-bottom: none;
  }
  .v-tab-small {
    min-width: initial !important;
    color: var(--v-gray900-base);
    font-weight: 500;
    font-size: 12px !important;
    line-height: 18px;
    padding: 8px 12px !important;
    margin-right: 4px !important;
  }

  .v-tab--active {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    background-color: white;
    letter-spacing: inherit;
  }

  .v-tabs-slider-wrapper {
    display: none;
  }
}

.order-tabs {
  .v-slide-group__prev {
    display: none;
  }

  .v-slide-group__next {
    display: none;
  }

  .v-slide-group__wrapper {
    .v-tab {
      width: 88px !important;
      min-width: 88px !important;
      padding: 8px 12px !important;
      white-space: nowrap;
      margin-right: 5px;
    }

    .v-tab:last-child {
      margin-right: 0;
    }
  }
}
</style>
