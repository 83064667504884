<template>
  <form>
    <sticky-header class="d-flex align-center justify-space-between">
      <slot name="header" :on-submit="onSubmit">
        <v-row align="center">
          <v-col cols="8" class="py-0">
            <div class="gray900--text text-h1 py-5 form-title">
              {{ title }}
            </div>
          </v-col>
          <v-col cols="4" align="right">
            <slot name="actions">
              <ui-button v-if="showBackBtn" variant="white" class="mx-3" @click="$router.push({ name: pathName })">
                {{ $t('base.back') }}
              </ui-button>
              <ui-button v-if="checkPermission" :disabled="isLoading" @click="onSubmit">
                <template #iconLeft>
                  <v-icon color="white" size="12">fi fi-sr-disk</v-icon>
                </template>
                {{ $t('base.save') }}
              </ui-button>
            </slot>
          </v-col>
        </v-row>
      </slot>
    </sticky-header>
    <slot></slot>
  </form>
</template>

<script>
import StickyHeader from '@/components/ui/StickyHeader'
import { hasPermission } from '@/helpers/permission'

export default {
  name: 'ui-form-entity',
  components: { StickyHeader },
  props: {
    title: {
      type: String,
      default: '',
    },
    pathName: {
      type: String,
      default: '',
    },
    permission: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    showBackBtn: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    checkPermission() {
      return this.permission.length ? this.hasPermission(this.permission) : true
    },
  },
  methods: {
    hasPermission,
    onSubmit() {
      this.$emit('onSubmit')
    },
  },
}
</script>

<style lang="scss" scoped>
.form-title {
  font-size: 24px!important;
  font-weight: 600;
}
</style>
