/*
    Danger!
    You should not remove any comments from this file, if comments will be removed generator will be broken
*/

import Vue from 'vue'
import Vuex from 'vuex'

import AuthModule from '@/store/modules/Auth.module'
import ModalModule from '@/store/modules/Modal.module'
import NotificationModule from '@/store/modules/Notification.module'
import ProfileModule from "@/store/modules/Profile.module";

// Generated modules imported under this 2 comments. DO NOT REMOVE ANY COMMENTS
// __generator_imports__
import BlogArticlesModule from '@/store/modules/BlogArticles.module'
import BlogCategoriesModule from '@/store/modules/BlogCategories.module'
import WaitingListModule from '@/store/modules/WaitingList.module'
import FileModule from "@/store/modules/File.module";
import HelpArticlesModule from "@/store/modules/HelpArticles.module";
import HelpCategoriesModule from "@/store/modules/HelpCategories.module";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    AuthModule,
    ModalModule,
    NotificationModule,
    ProfileModule,
    FileModule,
    // Generated modules under this comment. DO NOT REMOVE ANY COMMENTS
    // __imported_entities__
    BlogArticlesModule,
    BlogCategoriesModule,
    WaitingListModule,
    HelpArticlesModule,
    HelpCategoriesModule
  },
})
