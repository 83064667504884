export default [
  {
    path: '/auth',
    component: () => import('@/pages/auth/Auth'),
    children: [
      {
        name: 'loginPage',
        path: '/auth/login',
        meta: { title: 'authLogin.login' },
        component: () => import('@/pages/auth/Login'),
      },
      {
        name: 'restorePage',
        path: '/auth/restore',
        meta: { title: 'page.restore' },
        component: () => import('@/pages/auth/Restore'),
      },
      {
        name: 'recoveryPassword',
        path: '/auth/recovery-password/:token',
        meta: { title: 'page.recoveryPassword' },
        component: () => import('@/pages/auth/RecoveryPassword'),
      },
    ],
  },
]
