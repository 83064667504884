import StorageService from '@/services/storage.service'
import storageKeys from '@/constans/storageKeys'
import { defaultFilters } from '@/models/HelpCategories/helpers'
import TableOptions from '@/models/TableOptions'
import { omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'
import helpCategoriesRepository from "@/repository/generatedRepository/helpCategoriesRepository";

const state = {
  list: [],
  filters: StorageService.get(storageKeys.HELP_CATEGORIES_FILTERS) || { ...defaultFilters },
  tableOptions: TableOptions.defaultTableOptions(),
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  list: state => state.list,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadData(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.HELP_CATEGORIES_FILTERS, filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilters,
    })

    const { data } = await helpCategoriesRepository.list(preparedFilters)

    context.commit('setList', { data, filters })

    return { products: data.items, pagination: data.pagination }
  },
  async searchHelpCategories(context, filters) {
    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
    })

    const { data } = await helpCategoriesRepository.list(preparedFilters)

    return { products: data.items, pagination: data.pagination }
  },
  async loadItem(context, payload) {
    const {id, loc = 'en'} = payload
    const { data } = await helpCategoriesRepository.getLocale(id, {}, {headers: {locale: loc}})
    return data
  },
  createItem(context, payload) {
    const { loc, ...data } = payload
    return helpCategoriesRepository.post(data, {headers: {locale: loc}})
  },
  updateItem(context, payload) {
    const { id, loc, ...data } = payload
    return helpCategoriesRepository.put(id, data, {headers: {locale: loc}})
  },
  deleteItem(context, payload) {
    return helpCategoriesRepository.delete(payload)
  },
  filtersStorageReset() {
    StorageService.remove(storageKeys.HELP_CATEGORIES_FILTERS)
  },
  updateFiltersClientOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.HELP_CATEGORIES_FILTERS, updatedFilters)
  },
}

const mutations = {
  setList(state, { data, filters }) {
    state.list = [...data.items]
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
