export const defaultFilters = {
    pagination: {
        limit: 20,
        page: 1,
    },
    filter: {
    },
    sortBy: ['active', 'id'],
    sortDesc: [true, true],
    clientOptions: {
        filterPanelExpanded: false,
    },
}
