/*
    Danger!
    You should not remove any comments from this file, if comments will be removed generator will be broken
*/

// __generator_imports__
import helpRoute from './help'
import blogRoute from './blog'
import waitingListRoute from './waiting-list'

const routes = [
// __imported_entities__
  ...blogRoute,
  ...waitingListRoute,
  ...helpRoute,
]

export default routes;
