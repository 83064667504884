export default {
  // Generated storage keys under this comment. DO NOT REMOVE ANY COMMENTS below
  // __auto_generated_storage_keys__
BLOG_ARTICLES_FILTERS: 'filters_blog_articles',
BLOG_CATEGORIES_FILTERS: 'filters_blog_categories',
HELP_ARTICLES_FILTERS: 'filters_help_articles',
HELP_CATEGORIES_FILTERS: 'filters_help_categories',
WAITING_LIST_FILTERS: 'filters_waiting_list',
EXAMPLE_CRUD_FILTERS: 'filters_example_crud',
}
