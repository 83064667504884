export default [
    {
        path: '/blog',
        name: 'BlogPage',
        meta: {title: 'blog.listTitle'},
        component: () => import('@/pages/blog'),
    },
    {
        path: '/blog/articles/create',
        name: 'pageBlogArticlesCreate',
        meta: { title: 'blogArticles.createFormTitle', parent: {title: 'blog.listTitle', to: '/blog', tab: 'articles'} },
        component: () => import('@/pages/blog/blog-articles/create'),
    },
    {
        path: '/blog/articles/:id/edit',
        name: 'pageBlogArticlesEdit',
        meta: { title: 'blogArticles.editFormTitle', parent: {title: 'blog.listTitle', to: '/blog', tab: 'articles'} },
        component: () => import('@/pages/blog/blog-articles/id/edit'),
    },
]
